(<template>
  <div class="ticket-message">
    <tt-avatar :avatar="author.photoUrl"
               class="ticket-message__avatar" />
    <div class="ticket-message__wrapper">
      <div class="ticket-message__header">
        <div class="ticket-message__title">
          <p class="ticket-message__name">{{ authorName }}</p>
          <p class="ticket-message__role">{{ authorRole }}</p>
        </div>
        <p v-if="createdAt"
           class="ticket-message__description">{{ createdAtText }}</p>
      </div>
      <div class="ticket-message__body"
           v-html="message.body"></div>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      message: {
        type: Object,
        default() {
          return {
            id: '',
            body: '',
            createdAt: '',
            createdBy: {
              id: '',
              type: '',
              first: '',
              last: '',
              photoUrl: '',
              email: ''
            }
          };
        }
      }
    },
    computed: {
      author() {
        return this.message.createdBy || {};
      },
      authorName() {
        return `${this.author.first || ''} ${this.author.last || ''}`.trim();
      },
      authorRole() {
        return this.author.type === 'customer' ? this.$gettext('USER') : this.$gettext('STAFF');
      },
      createdAt() {
        if (this.message.createdAt) {
          return this.$moment(this.message.createdAt).format('DD.MM.YYYY HH:mm');
        }
      },
      createdAtText() {
        return this.$gettextInterpolate(this.$gettext('Posted on: %{date}'), {
          date: this.createdAt || ''
        });
      },
    }
  };
</script>

<style scoped>
  .ticket-message {
    display: flex;
    padding: 10px 0;
    border-top: 1px solid #d3d5de;
    font-size: 12px;
  }

  .ticket-message__avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .ticket-message__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
    margin-bottom: 10px;
  }

  .ticket-message__title {
    display: flex;
  }

  .ticket-message__name {
    margin-right: 5px;
    font-weight: bold;
  }

  .ticket-message__role {
    padding: 1px 5px;
    border: 1px solid #9196ad;
    border-radius: 4px;
    font-size: 10px;
  }

  .ticket-message__description {
    color: #9196ad;
  }
</style>
