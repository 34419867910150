(<template>
  <page-data-section :title="title"
                     class="one-ticket">
    <ticket-info :ticket="currentTicket"
                 class="one-ticket__section" />
    <button class="sk-btn sk-btn--white one-ticket__btn"
            @click="openReplyModal">{{ $gettext('Add a reply') }}</button>
    <div :class="{'in-progress': progressActive}"
         class="one-ticket__messages is-progress-bar">
      <ticket-message v-for="message in messages"
                      :key="message.id"
                      :message="message"
                      class="one-ticket__message" />
    </div>
  </page-data-section>
</template>)

<script>
  import {mapActions, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import TicketInfo from '@/components/ticket_system/TicketInfo';
  import TicketMessage from '@/components/ticket_system/TicketMessage';

  export default {
    asyncData({store, route}) {
      const ticketID = route.params.id;
      let promisesArr = [];

      if (!store.state.TicketSystemStore.currentTicket) {
        promisesArr = [...promisesArr, store.dispatch('OneTicketStore/getTicket', ticketID)];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'ticket-info': TicketInfo,
      'ticket-message': TicketMessage
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('OneTicketStore', {
        currentTicket: ({currentTicket}) => currentTicket || {}
      }),
      title() {
        return helpers.ticketSystemInfo.ticketCategory(this, this.currentTicket.category);
      },
      messages() {
        return this.currentTicket.messages || [];
      }
    },
    methods: {
      ...mapActions('OneTicketStore', [
        'addTicketMessage'
      ]),
      openReplyModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-ticket-message-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Send reply'),
            context: this
          }
        });
      },
      addReplyMessage(message) {
        this.progressActive = true;
        this.addTicketMessage({ticketId: this.currentTicket.id, message})
          .then(() => {
            this.progressActive = false;
          })
          .catch((error) => {
            this.progressActive = false;
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          });
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('OneTicketStore/clearStore');
      next();
    }
  };
</script>

<style src="@assets/css/page-data-section.css"></style>

<style scoped>
  .one-ticket {
    width: 100%;
    max-width: 737px;
    margin: 40px auto;
  }

  .one-ticket__btn {
    width: auto;
    margin-bottom: 30px;
    padding: 0 50px;
  }

  .one-ticket__message {
    margin-top: 20px;
  }

  .one-ticket__section {
    margin-bottom: 30px;
  }
</style>
