(<template>
  <div class="ticket-info">
    <div class="ticket-info__text-wrapper">
      <p v-if="createdAt"
         class="ticket-info__text">{{ createdAtText }}</p>
      <p v-if="updatedAt"
         class="ticket-info__text">{{ updatedAtText }}</p>
    </div>
    <div class="ticket-info__blocks-wrapper">
      <div v-for="(block, index) in infoBlocks"
           :key="index"
           class="ticket-info__block">
        <p class="ticket-info__block-title">{{ block.title }}</p>
        <p v-html="block.text"></p>
      </div>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      ticket: {
        type: Object,
        default() {
          return {
            id: '',
            category: '',
            status: '',
            jobIds: [],
            createdAt: ''
          };
        }
      }
    },
    computed: {
      createdAt() {
        if (this.ticket.createdAt) {
          return this.$moment(this.ticket.createdAt).format('DD.MM.YYYY HH:mm');
        }
      },
      createdAtText() {
        return this.$gettextInterpolate(this.$gettext('Created: %{date}'), {
          date: this.createdAt
        });
      },
      updatedAt() {
        if (this.ticket.updatedAt) {
          return this.$moment(this.ticket.createdAt).format('DD.MM.YYYY HH:mm');
        }
      },
      updatedAtText() {
        return this.$gettextInterpolate(this.$gettext('Updated: %{date}'), {
          date: this.createdAt
        });
      },
      infoBlocks() {
        return [{
          title: this.$gettext('Ticket nr.'),
          text: helpers.ticketSystemInfo.ticketId(this, this.ticket.id)
        }, {
          title: this.$gettext('Issue'),
          text: helpers.ticketSystemInfo.ticketCategory(this, this.ticket.category)
        }, {
          title: this.$gettext('Status'),
          text: helpers.ticketSystemInfo.ticketStatus(this, this.ticket.status)
        }, {
          title: this.$gettext('Assignment number(s)'),
          text: this.ticket.jobIds?.length
            ? this.ticket.jobIds.map((id) => `#${id}`).join('<br />')
            : '-'
        }];
      }
    }
  };
</script>

<style scoped>
  .ticket-info__blocks-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-radius: 5px;
    background-color: #e9eaef;
  }

  .ticket-info__block {
    flex-grow: 1;
    padding: 10px;
    border-right: 1px solid #000;
  }

  .ticket-info__text-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-bottom: 5px;
    margin-left: -15px;
  }

  .ticket-info__text {
    margin: 0 15px;
    color: #9196ad;
    font-size: 12px;
  }

  .ticket-info__block:last-child {
    border-right: none;
  }

  .ticket-info__block-title {
    font-size: 10px;
  }

  @media (max-width: 767px) {
    .ticket-info__blocks-wrapper {
      flex-wrap: wrap;
      padding: 0 10px;
    }

    .ticket-info__block {
      width: 100%;
      padding: 10px 0;
      border-right: none;
      border-bottom: 1px solid #000;
    }

    .ticket-info__block:last-child {
      border-bottom: none;
    }
  }
</style>
